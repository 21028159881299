var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"course-manager"},[_c('header',[_c('h5',[_vm._v("Courses and layouts")]),_c('span',{on:{"click":function($event){return _vm.$router.go(-1)}}},[(_vm.$route.name !== 'coursedirectory')?_c('Crossmark'):_vm._e()],1)]),_c('section',{staticClass:"tabs"},[_c('div',{class:{ active: _vm.view == 'OFFICIAL' },on:{"click":function($event){_vm.view = 'OFFICIAL'}}},[_vm._v(" Official ")]),_c('div',{class:{ active: _vm.view == 'CUSTOM' || _vm.view == 'CREATE' },on:{"click":function($event){_vm.view = 'CUSTOM'}}},[_vm._v(" Custom ")])]),(_vm.view == 'OFFICIAL')?_c('section',{staticClass:"search"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchString),expression:"searchString"}],staticClass:"search-field",attrs:{"type":"text","placeholder":"Search for courses"},domProps:{"value":(_vm.searchString)},on:{"keydown":function($event){return _vm.debouncedCourseSearch()},"input":function($event){if($event.target.composing){ return; }_vm.searchString=$event.target.value}}}),_c('Search',{attrs:{"color":"midnight"}})],1):_vm._e(),(_vm.view == 'OFFICIAL')?_c('section',{staticClass:"public-courses"},[_c('div',{staticClass:"courses"},[_c('div',{staticClass:"displayed-courses"},_vm._l((_vm.shownPublicCourses),function(course){return _c('TheCourseCard',{key:course.id,attrs:{"usersPosition":_vm.usersPosition,"course":course,"applyLayoutEnabled":false}})}),1),(
          _vm.shownPublicCourses.length > 0 &&
          _vm.shownPublicCourses.length < _vm.filteredPublicCourses.length
        )?_c('div',{staticClass:"show-more",on:{"click":function($event){_vm.numberOfShownCourses += 15}}},[_vm._v(" Show more courses ")]):_vm._e()])]):_vm._e(),(_vm.view == 'CUSTOM')?_c('section',{staticClass:"custom-courses"},[_c('div',{staticClass:"courses"},[_c('div',{staticClass:"displayed-courses"},_vm._l((_vm.filteredCustomCourses),function(course){return _c('TheCourseCard',{key:course.id,attrs:{"course":course,"applyLayoutEnabled":false},on:{"create-layout":function($event){return _vm.createLayout($event)}}})}),1)])]):_vm._e(),(_vm.view == 'CREATE')?_c('section',{staticClass:"create"},[_c('TheCourseCreator',{attrs:{"course":_vm.referenceCourse,"layoutonly":_vm.createLayoutOnly},on:{"back":function($event){return _vm.refresh()}}})],1):_vm._e(),(_vm.view == 'CUSTOM')?_c('footer',{staticClass:"mt-2"},[_c('StandardSolidButton',{attrs:{"title":"Create new course "},on:{"click":function($event){_vm.view = 'CREATE';
        _vm.createLayoutOnly = false;}}})],1):(
      _vm.view == 'OFFICIAL' &&
      _vm.searchString != '' &&
      _vm.filteredPublicCourses.length == 0
    )?_c('footer',[_vm._m(0)]):(_vm.view == 'OFFICIAL' && _vm.searchString.length > 0)?_c('footer',[_vm._m(1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" We can’t find a course matching your search. Please help us build our course directory by sending us an "),_c('a',{attrs:{"href":"mailto:hello@tjing.se"}},[_vm._v("email")]),_vm._v(". ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_vm._v(" If you believe a course is incorrect then please drop us an "),_c('a',{attrs:{"href":"mailto:hello@tjing.se"}},[_vm._v("email")]),_vm._v(". ")])}]

export { render, staticRenderFns }