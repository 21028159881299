<template>
  <main class="course-manager">
    <header>
      <h5>Courses and layouts</h5>
      <span @click="$router.go(-1)"
        ><Crossmark v-if="$route.name !== 'coursedirectory'"
      /></span>
    </header>
    <section class="tabs">
      <div @click="view = 'OFFICIAL'" :class="{ active: view == 'OFFICIAL' }">
        Official
      </div>
      <div
        @click="view = 'CUSTOM'"
        :class="{ active: view == 'CUSTOM' || view == 'CREATE' }"
      >
        Custom
      </div>
    </section>
    <section class="search" v-if="view == 'OFFICIAL'">
      <input
        type="text"
        class="search-field"
        v-model="searchString"
        placeholder="Search for courses"
        @keydown="debouncedCourseSearch()"
      />
      <Search color="midnight" />
    </section>

    <section class="public-courses" v-if="view == 'OFFICIAL'">
      <div class="courses">
        <div class="displayed-courses">
          <TheCourseCard
            :usersPosition="usersPosition"
            :course="course"
            v-for="course in shownPublicCourses"
            :key="course.id"
            :applyLayoutEnabled="false"
          />
        </div>
        <div
          class="show-more"
          @click="numberOfShownCourses += 15"
          v-if="
            shownPublicCourses.length > 0 &&
            shownPublicCourses.length < filteredPublicCourses.length
          "
        >
          Show more courses
        </div>
      </div>
    </section>

    <section class="custom-courses" v-if="view == 'CUSTOM'">
      <div class="courses">
        <div class="displayed-courses">
          <TheCourseCard
            :course="course"
            v-for="course in filteredCustomCourses"
            :key="course.id"
            :applyLayoutEnabled="false"
            @create-layout="createLayout($event)"
          />
        </div>
      </div>
    </section>

    <section class="create" v-if="view == 'CREATE'">
      <TheCourseCreator
        :course="referenceCourse"
        :layoutonly="createLayoutOnly"
        @back="refresh()"
      />
    </section>

    <footer v-if="view == 'CUSTOM'" class="mt-2">
      <StandardSolidButton
        title="Create new course "
        @click="
          view = 'CREATE';
          createLayoutOnly = false;
        "
      />
    </footer>
    <footer
      v-else-if="
        view == 'OFFICIAL' &&
        searchString != '' &&
        filteredPublicCourses.length == 0
      "
    >
      <p>
        We can’t find a course matching your search. Please help us build our
        course directory by sending us an
        <a href="mailto:hello@tjing.se">email</a>.
      </p>
    </footer>
    <footer v-else-if="view == 'OFFICIAL' && searchString.length > 0">
      <p>
        If you believe a course is incorrect then please drop us an
        <a href="mailto:hello@tjing.se">email</a>.
      </p>
    </footer>
  </main>
</template>

<script>
import { filter, debounce } from "lodash";
import locationMixin from "@/mixins/locationMixin.js";
import Search from "@/assets/icons/Search";
import Crossmark from "@/assets/icons/Crossmark";
import TheCourseCard from "@/components/TheCourseCard";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import TheCourseCreator from "@/components/TheCourseCreator";

export default {
  name: "TjingAdminCourseManager",
  components: {
    Search,
    TheCourseCard,
    StandardSolidButton,
    TheCourseCreator,
    Crossmark,
  },
  mixins: [locationMixin],
  data() {
    return {
      view: "OFFICIAL",
      createLayoutOnly: false,
      searchString: "",
      loadedCourseDetails: [],
      customCourses: [],
      publicCourses: [],
      numberOfShownCourses: 15,
      referenceCourse: null,
      usersPosition: {},
    };
  },
  watch: {
    view: function (newVal, oldVal) {
      window.scrollTo(0, 0);
    },
    searchString: function (newVal, oldVal) {
      this.numberOfShownCourses = 15;
    },
  },
  computed: {
    isUserLoggedIn() {
      if (this.$store.getters.isUserLoggedIn) {
        return true;
      } else {
        return false;
      }
    },
    filteredCustomCourses() {
      return this.customCourses;
    },
    shownPublicCourses() {
      return this.filteredPublicCourses.slice(0, this.numberOfShownCourses);
    },
    filteredPublicCourses() {
      if (this.searchString == "") {
        return [];
      }

      return filter(
        this.publicCourses,
        (o) =>
          o.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
          o.address.toLowerCase().includes(this.searchString.toLowerCase())
      );
    },
  },
  methods: {
    debouncedCourseSearch: debounce(
      function () {
        this.courseSearch();
      },
      500,
      { maxWait: 800 }
    ),
    async courseSearch() {
      let courses = await this.$axios({
        data: {
          query: `
          {
            courses(first: 5, filter:{ search:"${this.searchString}" })
            {
              id
              name
              address
              type
              geolocation{
                lng
                lat
              }
              layouts{
                type
                id
                name
                course{
                  name
                  id
                }
              }
            }
          }
          `,
        },
      });

      this.publicCourses = courses.data.data.courses;
    },
    refresh() {
      this.view = "OFFICIAL";
      this.loadMyCourses();
    },
    createLayout(course) {
      this.referenceCourse = course;
      this.view = "CREATE";
      this.createLayoutOnly = true;
    },
    async loadMyCourses() {
      let myCourses = await this.$axios({
        headers: {
          Authorization: this.$store.state.user.sessionInfo.token,
        },
        data: {
          query: `
            {
              me {
                courses {
                  id
                  name
                  address
                  type
                  geolocation {
                    lng
                    lat
                  }
                  layouts {
                    id
                    name
                  }
                }
              }
            }
          `,
        },
      });

      this.customCourses = myCourses.data.data.me.courses;
    },
  },
  mounted() {
    if (this.isUserLoggedIn) {
      this.loadMyCourses();
    } else {
      this.$router.push({ name: "login" });
    }
  },
  async beforeMount() {
    try {
      this.usersPosition = await this.getLocation();
    } catch (err) {
      if (err.code == 1) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Location services disabled",
          type: "error",
        });
      }
    }
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.course-manager {
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 32px;
  footer {
    padding: 0 15px;
    margin-top: 48px;
    margin-bottom: 48px;
    p {
      text-align: center;
      font-size: 16px;
    }
  }
}
a {
  color: $ocean;
  &:hover {
    border-bottom: 1px solid $ocean;
  }
}
header {
  display: block;
  width: 100%;
  text-align: center;
  position: relative;
  h5 {
    @include Gilroy-Bold;
    font-size: 16px;
    position: relative;
  }
  span {
    position: absolute;
    right: 30px;
    top: 3px;

    border-radius: 50%;
    display: flex;
    align-items: center;
    &:hover {
      background-color: $mist;
    }
  }
}
.displayed-courses {
  main {
    margin-bottom: 24px;
  }
}
.tabs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 32px;
  cursor: pointer;

  div {
    font-size: 16px;
    height: 32px;
    width: 92px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.5px;
    transition: all ease 0.3s;
    background-color: $mist;

    &.active {
      background: $ocean;
      color: white;
      @include Gilroy-Bold;
      &:hover {
        background: $ocean;
        color: white;
        @include Gilroy-Bold;
      }
    }
    &:hover {
      background: $fog;
    }

    &.active {
      cursor: default;
    }
  }
}
.search {
  padding: 0 15px;
  margin-top: 32px;
  position: relative;

  svg {
    position: absolute;
    left: 32px;
    top: 16px;
  }
  .search-field {
    width: 100%;
    height: 48px;
    border-radius: 50px;
    border: 1px solid $mist;
    background-color: $mist;
    padding-left: 50px;
    padding-right: 50px;
    outline: none;
    transition: all ease 0.3s;
    &::placeholder {
      @include Gilroy-Medium;
      color: $blizzard;
    }
    &:hover {
      border: 1px solid $fog;
    }
  }
}
.public-courses,
.custom-courses {
  padding: 0 15px;
  margin-top: 32px;

  .show-more {
    cursor: pointer;
    text-align: center;
    margin-top: 24px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $fog;
    @include Gilroy-Bold;
    border-radius: 6px;
    transition: all ease 0.3s;
    user-select: none;

    &:hover {
      background-color: $sleet;
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .course-manager {
    background-color: white;
    border-radius: 6px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    header {
      margin-top: 48px;
      h5 {
        font-size: 24px;
      }
      span {
        top: 8px;
      }
    }
    header,
    section,
    footer {
      width: 600px;
    }
  }
}
</style>
