<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path class="a" d="M20,0A20,20,0,1,1,0,20,20,20,0,0,1,20,0Z" />
    <line class="b" x2="14" transform="translate(13 20)" />
  </svg>
</template>

<script>
export default {
  name: "IconTemplate",
};
</script>

<style lang="scss" scoped>
svg {
  &:hover {
    .a {
      fill: #d2d7dc;
    }
  }
}
.a {
  transition: all ease 0.3s;
  fill: #e7e9ec;
}
.b {
  fill: none;
  stroke: #2d323c;
  stroke-width: 2px;
}
</style>