<template>
  <main>
    <section class="header">
      <p v-if="layoutonly">
        Create a new layout for <br />
        {{ course.name }}
      </p>
      <p v-else>Create a new course</p>
    </section>
    <section class="names">
      <DefaultTextInput
        v-model="courseName"
        title="Course name"
        placeholder="Add course name (min 3 chars)"
        :maxlength="100"
        v-if="layoutonly == false"
      />
      <div class="tags">
        <p>Layout name</p>

        <div class="holes">{{ layout.holes.length }} holes</div>
        <div class="par">Par {{ parCounter }}</div>
      </div>
      <DefaultTextInput
        v-model="layout.name"
        placeholder="Add layout name (min 3 chars)"
        :maxlength="100"
      />
    </section>
    <section class="layout">
      <div class="titles">
        <span class="number">Hole</span>
        <span class="name">Custom name</span>
        <span class="par">Par</span>
      </div>
      <div class="holes" v-for="hole in layout.holes" :key="hole.number">
        <span class="number">{{ hole.number }}</span>
        <span class="name"
          ><input
            type="text"
            placeholder="Add"
            value=""
            maxlength="3"
            v-model="hole.name"
            :class="{ active: hole.name.length > 0 }"
        /></span>
        <span class="par">
          <span @click="decreasePar(hole)"><DecreasePar /></span>
          <p>{{ hole.par }}</p>
          <span @click="increasePar(hole)"><IncreasePar /></span>
        </span>
      </div>
      <div class="adjustments">
        <span class="remove" @click="removeHole()"> <Remove />Remove hole</span>
        <span class="add" @click="addHole()"
          ><Add color="grass" />Add hole</span
        >
      </div>
    </section>
    <section class="summary">
      <p>{{ layout.holes.length }} Holes<span>•</span>Par {{ parCounter }}</p>
    </section>

    <section class="submit-as-official" v-if="layoutonly == false">
      <h5>Add to Tjing's official course database</h5>
      <DefaultToggle v-model="submitAsOfficial" />
      <slide-up-down :active="submitAsOfficial" :duration="300">
        <div class="additional-details">
          <DefaultTextInput
            v-model="courseCountry"
            placeholder="Add country"
            :maxlength="100"
            title="Country"
          />
          <DefaultTextInput
            v-model="courseAddress"
            placeholder="Add course address"
            :maxlength="100"
            title="Address"
          />
          <DefaultTextInput
            v-model="courseWebsite"
            placeholder="Add website (optional)"
            :maxlength="100"
            title="Website"
          />
          <p>
            In addition to creating this custom course that you can use right
            away, this layout will also be submitted for an official review.
            Once reviewed and accepted it will be added to Tjing’s official
            course database for all users to use.
          </p>
        </div>
      </slide-up-down>
    </section>

    <footer>
      <StandardBorderedButton
        :title="layoutonly ? 'Discard layout' : 'Discard course'"
        class="discard"
        @click="$emit('back')"
      />

      <StandardSolidButton
        v-if="layoutonly"
        title="Save layout"
        class="save"
        :disabled="layout.name.length < 3"
        @click="save()"
      />

      <StandardSolidButton
        v-else
        :title="submitAsOfficial ? 'Save & Submit' : 'Save course'"
        class="save"
        :disabled="layout.name.length < 3 || courseName.length < 3"
        @click="save()"
      />
    </footer>
  </main>
</template>

<script>
import DefaultTextInput from "@/components/UIElements/DefaultTextInput";
import StandardSolidButton from "@/components/UIElements/StandardSolidButton";
import StandardBorderedButton from "@/components/UIElements/StandardBorderedButton";


import IncreasePar from "@/assets/icons/IncreasePar";
import DecreasePar from "@/assets/icons/DecreasePar";
import Add from "@/assets/icons/Add";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import Remove from "@/assets/icons/Remove";

import SlideUpDown from "vue-slide-up-down";

export default {
  name: "TheCourseCreator",
  props: ["layoutonly", "course"],
  components: {
    DefaultTextInput,
    StandardSolidButton,
    StandardBorderedButton,
    IncreasePar,
    DecreasePar,
    Add,
    Remove,
    DefaultToggle,
    SlideUpDown,
  },
  data() {
    return {
      layout: {
        name: "",
        holes: [],
      },
      courseName: "",
      submitAsOfficial: false,
      courseAddress: "",
      courseCountry: "",
      courseWebsite: "",
    };
  },
  computed: {
    parCounter() {
      let par = 0;
      this.layout.holes.forEach((hole) => {
        par += hole.par;
      });
      return par;
    },
  },
  methods: {
    async submitCourseForReview() {
      let sendForReview = await this.$axios({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        url:
          "https://us-central1-tjing-3bbea.cloudfunctions.net/courseUpdateNotificationToDiscord",
        data: {
          embeds: [
            {
              title: "New course to Add",
              fields: [
                {
                  name: "Course name",
                  value: this.courseName,
                },
                {
                  name: "Layout name",
                  value: this.layout.name,
                },
                {
                  name: "Country",
                  value: this.courseCountry == "" ? "-" : this.courseCountry,
                },
                {
                  name: "Address",
                  value: this.courseAddress == "" ? "-" : this.courseAddress,
                },
                {
                  name: "Website",
                  value: this.courseWebsite == "" ? "-" : this.courseWebsite,
                },
                {
                  name: "Layout",
                  value: JSON.stringify(this.layout.holes),
                },
              ],
              author: {
                name: `${this.$store.getters.userInfo.firstName} ${this.$store.getters.userInfo.lastName} <${this.$store.getters.userInfo.email}>`,
              },
            },
          ],
          username: "Course Review",
        },
      });
    },
    save() {
      if (this.layoutonly) {
        if (this.layout.name.length > 2) {
          this.saveLayout(this.course.id);
        }
      } else {
        if (this.layout.name.length > 2 && this.courseName.length > 2) {
          this.saveLocation();
          if (this.submitAsOfficial) {
            this.submitCourseForReview();
          }
        }
      }
    },
    async saveLocation() {
      try {
        let newLocation = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation CreateCourse($Createcourse: CourseInput!){
            CreateCourse(input:$Createcourse){
              id
            }
          }
          `,
            variables: {
              Createcourse: {
                name: this.courseName,
              },
            },
          },
        });

        this.saveLayout(newLocation.data.data.CreateCourse.id);
      } catch (error) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    async saveLayout(courseId) {
      let newHoles = this.layout.holes.map((hole) => {
        if (hole.name == null || hole.name == "") {
          delete hole.name;
        }

        delete hole.id;

        return hole;
      });

      try {
        let updatedLayout = await this.$axios({
          headers: { Authorization: this.$store.state.user.sessionInfo.token },
          data: {
            query: `
          mutation Createlayout($Createlayout: LayoutInput!){
            CreateLayout(courseId:"${courseId}", input:$Createlayout){
              id
            }
          }
          `,
            variables: {
              Createlayout: {
                name: this.layout.name,
                holes: newHoles,
              },
            },
          },
        });

        this.$store.dispatch("showMessage", {
          show: true,
          message: "Saved",
          type: "success",
        });

        this.$emit("back");
      } catch (err) {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Error",
          type: "error",
        });
      }
    },
    decreasePar(hole) {
      if (hole.par > 1) {
        return hole.par--;
      }
    },
    increasePar(hole) {
      if (hole.par < 12) {
        return hole.par++;
      }
    },
    addHole() {
      if (this.layout.holes.length < 36) {
        this.layout.holes.push({
          number: this.layout.holes.length + 1,
          name: "",
          par: 3,
        });
      } else {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Maximum of 36 holes",
          type: "error",
        });
      }
    },
    removeHole() {
      if (this.layout.holes.length > 3) {
        this.layout.holes.pop();
      } else {
        this.$store.dispatch("showMessage", {
          show: true,
          message: "Minimum of 3 holes",
          type: "error",
        });
      }
    },
    createDefaultLayout() {
      for (let index = 0; index < 18; index++) {
        this.layout.holes.push({ number: index + 1, name: "", par: 3 });
      }
    },
  },
  mounted() {},
  beforeMount() {
    this.createDefaultLayout();
  },
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.submit-as-official {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
  flex-wrap: wrap;
  .additional-details {
    width: 100%;

    .default-inputfield {
      margin-top: 12px;
    }
  }
  p {
    font-size: 14px;
    color: $strom;
    margin-top: 12px;
    margin-bottom: 0;
  }
}
.tags {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  div {
    border-radius: 4px;

    padding: 3px 5px;
    color: $strom;
    font-size: 12px;
    @include Gilroy-Bold;
    margin-left: auto;
    display: flex;
    &.holes {
      background-color: #e5f8f8;
      border: 1px solid #9be1e4;
      color: $ocean;
    }
    &.par {
      margin-left: 8px;
      background-color: $mist;
      border: 1px solid $sleet;
      color: $strom;
    }
  }
}
.header {
  margin: 30px 0 40px 0;
  p {
    font-size: 18px;
    @include Gilroy-Bold;
    text-align: center;
  }
}

section {
  padding: 0 15px;

  h5 {
    font-size: 16px;
    margin: 0;
  }
}

footer {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  margin-bottom: 64px;
  .save,
  .discard {
    width: 45%;
  }
}
.summary {
  border-top: 1px solid $fog;
  border-bottom: 1px solid $fog;
  margin: 32px 15px;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-size: 20px;
    @include Gilroy-Bold;
    margin: 0;

    span {
      margin: 0 15px;
    }
  }
}
.adjustments {
  display: flex;
  justify-content: space-between;
  align-items: center;

  margin-top: 24px;
  .remove {
    display: flex;
    align-items: center;
    color: $dusk;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
  .add {
    display: flex;
    align-items: center;
    color: $grass;
    cursor: pointer;

    svg {
      width: 20px;
      height: 20px;
      margin-right: 8px;
    }
  }
}
.layout {
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
    margin-bottom: 16px;
    span {
      text-align: center;
      font-size: 16px;
    }
    .number {
      width: 35px;
    }
    .name {
      width: calc(40% - 35px);
    }
    .par {
      width: calc(50% - 35px);
    }
  }
  .holes {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;

    span {
      text-align: center;
    }
    .number {
      width: 35px;
      @include Gilroy-Bold;
      font-size: 20px;
    }
    .name {
      width: calc(50% - 35px);
      input {
        border: 1px solid $blizzard;
        border-radius: 6px;
        width: 64px;
        height: 32px;
        font-size: 20px;
        outline: none;
        text-align: center;
        padding-bottom: 2px;
        transition: all ease 0.3s;

        &:hover {
          border: 1px solid $midnight;
        }

        &:focus {
          &::placeholder {
            opacity: 0;
          }
        }

        &::placeholder {
          color: $blizzard;
          font-size: 14px;
        }

        &.active {
          background-color: $midnight;
          color: white;
          @include Gilroy-Bold;
        }
      }
    }
    .par {
      width: calc(50% - 35px);
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-size: 20px;
        @include Gilroy-Bold;
        margin: 0;
      }
    }
  }
}

@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 1200px) {
  .default-inputfield {
    width: 100%;
  }

  .layout {
    .holes,
    .titles {
      .name {
        width: 20%;
      }
      .number {
        user-select: none;
      }
      .par {
        width: 30%;

        p {
          user-select: none;
        }
        svg {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
