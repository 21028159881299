<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <circle class="a" cx="20" cy="20" r="20" />
    <line class="b" y2="16" transform="translate(20 12)" />
    <line class="b" x2="16" transform="translate(12 20)" />
  </svg>
</template>

<script>
export default {
  name: "IncreasePar",
};
</script>

<style lang="scss" scoped>
svg {
  &:hover {
    .a {
      fill: #d2d7dc;
    }
  }
}

.a {
  fill: #e7e9ec;
  transition: all ease 0.3s;
}
.b {
  fill: none;
  stroke: #2d323c;
  stroke-width: 2px;
}
</style>